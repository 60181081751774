import React, {Component} from 'react';
import Layout from "../components/layout"
import HeaderContentOffer from "../components/HeaderContentOffer"
import BackgroundHero from "../components/BackgroundHero"
import ServicesOffer from "../components/ServicesOffer"
import Benefits from "../components/Benefits"
import OfferIconServices from "../components/OfferIconServices";
import OfferIconInner from "../components/OfferIconInner";
import DTPIcon from "../assets/svgIcon/DTP.svg";
import GraphicIcon from "../assets/svgIcon/graphic.svg";
import DesignIcon from "../assets/svgIcon/design.svg";
import SEO from "../components/seo"




class Graphic extends Component {
    render() {
        return (
            <Layout footerDesc="Imagination is more important than knowledge. - Albert Einstein">
              <SEO title="Projekty graficzne , logotypy, kalendarze, gazetki Kielce | CodersBros"
                   description="Chcemy zmieniać świat na lepszy, dlatego robimy piękne i odważne projekty. Nasza firma ma ponad 20-letnie
                   doświadczenie w dziedzinie DTP i procesu Prepress."
              />
                <HeaderContentOffer title="Grafika"
                                    desc="Możemy wykonać dla Państwa: obróbkę cyfrową zdjęć, ulotki, reklamy prasowe, kalendarze, gazetki produktowe, katalogi, wizytówki, papier firmowy, książki, instrukcje, logotypy, ikony, itp."/>
                <BackgroundHero img="graphic"/>
                <ServicesOffer title="Grafika w CodersBros"
                               smallTitle="co oferujemy?">
                    <OfferIconServices>
                        <DTPIcon/>
                        <OfferIconInner
                            title="DTP i Prepress"
                            desc="Chcemy zmieniać świat na lepszy, dlatego robimy piękne i odważne projekty. Nasza firma ma ponad 20-letnie doświadczenie w dziedzinie DTP i procesu Prepress. Możemy wykonać dla Państwa: ulotki, reklamy prasowe, kalendarze książkowe lub ścienne, gazetki produktowe, katalogi lub katalogi okolicznościowe, wizytówki, papier firmowy, książki, instrukcje, logotypy, ikony, itp."
                        />
                    </OfferIconServices>
                    <OfferIconServices>
                        <GraphicIcon/>
                        <OfferIconInner
                            title="Tekst i Zdjęcia"
                            desc="W swojej ofercie posiadamy również: profesjonalną edycję i retusz oraz usuwanie zbędnego tła w zdjęciach, przenoszenie tekstu z dowolnego nośnika do komputera, wykonanie: banerów graficznych na strony internetowe lub do Social Mediów, multimedialnych prezentacji, itp."
                        />
                    </OfferIconServices>
                    <OfferIconServices>
                        <DesignIcon/>
                        <OfferIconInner
                            title="Kreacja i Design"
                            desc="Skuteczny projekt graficzny i marketing zawsze idą w parze, dlatego dokładamy wszelkich starań, aby nasze projekty były zgodne z panującymi na rynku trendami graficznymi. Słuchamy naszych Klientów i przełamujemy wyznaczone granice, dlatego z każdym projektem stajemy się coraz lepsi."
                        />
                    </OfferIconServices>
                </ServicesOffer>
                {/*<Benefits title="Korzyści dla Ciebie ze współpracy z CodersBros"*/}
                {/*          smallTitle="dlaczego my?"*/}
                {/*          benefitsArr={["Optymalizacja wydajności sklepu nopCommerce",*/}
                {/*              "Tworzenie aplikacji mobilnych",*/}
                {/*              "Niestandardowy rozwój",*/}
                {/*              "Tworzenie motywów (motywy mobilne i stacjonarne)",*/}
                {/*              "Migracja danych nopCommerce",*/}
                {/*              "Uaktualnienie nopCommerce Integracja z CDN"]}*/}
                {/*/>*/}
            </Layout>
        );
    }
}


export default Graphic;
